import React from "react";
import team001 from "../../images/t5.jpg";
// import { Link } from 'gatsby-plugin-modal-routing'

import ConditionalLayout from "../../components/ConditionalLayout";
// import SEO from "../../components/seo"

const SecondPage = () => (
  <ConditionalLayout>
    <h3 className="title-3">Cameron Shepherd</h3>
    <h4 className="">Director</h4>
    <div className="card">
      <div className="card-body">
        <div className="team-img">
          <img alt="About team" className="img-fluid" src={team001} />
        </div>
        <p>
          Cameron is a founding Director and member of Speckle Park Group Ltd
          and comes from a background of banking, finance, accounting, and
          education.
        </p>
        <p>
          Cameron has experience in retail, commercial and international
          banking, which combined with his accounting qualifications and
          experience sees him focusing on the company’s financial side.{" "}
        </p>
        <p>
          Previously holding CEO and CFO positions in the online and vocational
          education industries, he is currently the CFO of a large
          telecommunication network construction company.{" "}
        </p>
        <p>
          Cameron also holds Directors roles and sits on the boards of companies
          as diverse as a Community Bank, a Software Development Company,
          Residential & Commercial Property Construction Companies, and his own
          financial services business.
        </p>
        <p>
          His interest in the Speckle Park Cattle industry comes from his
          association and work with Greenhaven Speckle Park Pty Ltd, a
          successful purebred Speckle Park breeding operation located on the Mid
          North Coast of NSW.
        </p>
      </div>
    </div>
  </ConditionalLayout>
);

export default SecondPage;
